import { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import jwtService from '../../shared/services/jwt-services/jwt-service';
import TenantContext from '../contexts/TenantCotext';
import UserContext from '../contexts/UserContext';

function Nav(props) {

    let history = useHistory();
    let location = useLocation();
    const [User, setUser] = useContext(UserContext);
    ////console.log(User);

    
    const [Tenant, setTenant] = useContext(TenantContext);
    const [data,setData] = useState({});

    const logout = () => {
        setUser(null);
        jwtService.removeTokens();
        const login =`/${Tenant.basePath}/login`; 
        if(location.pathname !== login) {
            //console.log(login);
            history.push(login);
        }
    }

    
    return (
        <nav className="bg-white shadow sb-topnav navbar navbar-expand shadow bg-white">           
                <img className="header-logo-img" src={Tenant.logo} alt="Aswida Logo Image" />
            {/*<button className="btn btn-link btn-sm order-1 order-lg-0 btn-transparent-dark"*/}
            {/*id="sidebarToggle"><i className='bx bx-menu'></i>*/}
            {/*</button>*/}

            {/*<nav className="navbar navbar-expand-lg navbar-light bg-light">*/}
            {/*    <h1 className="navbar-brand" >{}</h1>*/}
            {/*    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">*/}
            {/*        <span className="navbar-toggler-icon"></span>*/}
            {/*    </button>*/}
            {/*    <div className="col-md-12 collapse navbar-collapse" id="navbarNavAltMarkup">*/}
            {/*        <div className="col-md-6 navbar-nav">*/}
            {/*                <h1>{Tenant.tenantName.toUpperCase()}</h1>*/}
            {/*             /!* <li className="nav-item nav-link"><Link to='/login'>Login</Link></li> *!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</nav>*/}
            <div className="singout">
                <div className="col-md-12">
                    <div className="col-md-6">
                         <button className="btn btn-primary btn-sm" onClick={logout} >Sign Out</button>
                    </div>
                </div>
            </div>

</nav>
    );
}

export default Nav;
