import { useContext, useEffect, useState } from 'react';
import '../../App.scss';
import { useHistory, useLocation } from "react-router-dom";
import UserContext from '../../protected-components/contexts/UserContext';
import Validators from '../../shared/validators/validator';
import JWTService from '../../shared/services/jwt-services/jwt-service';
import LoginService from './login-services/login-service';
import loginService from './login-services/login-service';
import TenantContext from '../../protected-components/contexts/TenantCotext';
import PropagateLoader from "react-spinners/PropagateLoader";
import LoadingPage from '../../shared/components/LoadingPage';
import userService from '../../shared/services/user-services/userService';
import PasswordField from '../../shared/components/PasswordField';
import forgetPasswordService from '../forget-password/forget-password-services/forget-password-service';

function Login(props) {

    const history = useHistory();
    const location = useLocation();
    const [User, setUser] = useContext(UserContext);
    const [Tenant, setTenant] = useContext(TenantContext);

    const [loginButton, setLoginButton] = useState('Sign In');
    const [dynamicStyle, setDynamicStyle] = useState('#FFFFFF');
    const [loading, setLoading] = useState(true);
    const [showPassoword, setShowPassoword] = useState('password');

    let loadingSpinner = <PropagateLoader
        css={'left: 50%; right : 50%; top: -5px'}
        size={10}
        color={"#FFFFFF"}
        loading={true}
    />

    if (Tenant.colors != null && dynamicStyle == '#FFFFFF') {
        setDynamicStyle(Tenant.colors.background);
    }

    useEffect(() => {

        if (JWTService.getAccessToken() != null) {
            if (location.pathname != "/") {
                history.push(`/${props.basePath}`);
            }
            //setLoading(false);
        } else {
            setLoading(true);
            if (JWTService.getRefreshToken() != null && Tenant.clientId !== null) {
                loginService.verifyUser().then(user => {
                    if (location.pathname != "/") {
                        history.push(`/${props.basePath}`);
                    }
                }).catch(err => {
                    setLoading(false);
                    setUser(null);
                });
            } else {
                setLoading(false);
                setUser(null);
            }
        }
    }, []);

    const [LoginUser, setLoginUser] = useState(
        {
            username: "",
            password: "",
            newPassword: "",
            confirmNewPassword: "",
            code: "",
            newPasswordTextbox: false,
            newPasswordShow: false,
            passwordShow: false,
            showNewComfirmPassword: false,
            passwordStrength: false,
            codeTextbox: false,
            errorMessage: "",
            keepSignIn: false,
            resendTimeout : 0
        }
    );

    const keepSignIn = (e) => {
        setLoginUser({ ...LoginUser, keepSignIn: !LoginUser.keepSignIn });
    }
    const goToForgetPassword = (e) => {
        e.preventDefault();
        history.push(`/${props.basePath}/forget-password`);
    }
    const goTOsignUpStudent = (e) => {
        e.preventDefault();
        if (process.env.REACT_APP_ENV == "localhost") {
            window.location.href = `http://localhost:4200/${props.basePath}/student/sign-up`;
        } else if (process.env.REACT_APP_ENV == "prod") {
            window.location.href = `https://99ticks.bydatabox.io/student/${props.basePath}/student/sign-up`;
        } else {
            window.location.href = `https://${process.env.REACT_APP_ENV}.student.99ticks.tk/${props.basePath}/student/sign-up`;
        }
    }
    const resendCode = () =>{
        setLoginUserError("");
        let user =
        {
            "clientId": Tenant.clientId,
            "clientSecret": ""
        };
        if (!Validators.isEmptyOrNullOrUndefined(LoginUser.username)) {
            user.username = LoginUser.username;
            forgetPasswordService.resendCode(user).then(data =>{
                setLoginButton('Verify');
                startCount(25);
            }).catch(error =>{
                console.log(error);
                setLoginButton('Verify');
                let response = error.response.data;
                setLoginUserError(response.message);
            });
        }
    }
    const startCount = (num) => {
        console.log(num);
        if(num != -1) {
            setTimeout(function(){ 
                setLoginUser({ ...LoginUser, resendTimeout: num});
                startCount(num-1);
            }, 1000);
        }
    }

    const login = (user) => {
        LoginService.signIn(user).then(data => {
            var responseData = data.data.data;
            JWTService.setAccessToken(responseData.accessToken, Tenant.clientId);
            if (LoginUser.keepSignIn == true) {
                JWTService.setRefreshToken(responseData.refreshToken);
            }
            var userData = JWTService.getAccessToken();
            const redirect = JWTService.getRedirectUrl();
            if (redirect != null) {
                JWTService.removeRedirectUrl();
                window.location.href = redirect;
            } else {
                return userService.getUser().then(loggedUser => {
                    if (loggedUser.userRole && loggedUser.userRole == "Student") {
                        window.location.href = process.env.REACT_APP_STUDENT_URL + props.basePath + "/home";
                    } else if (user.userRole && user.userRole == "Teacher" && HasPermission('P7',loggedUser.permissions)) {
                        window.location.href = process.env.REACT_APP_TUTER_URL + props.basePath + "/home";
                    } else {
                        JWTService.removeRedirectUrl();
                        history.push(`/${props.basePath}`);
                    }
                }).catch(err => {
                    //console.log(err);
                    JWTService.removeTokens();
                    setLoginUserError("Incorrect email or password");
                });
            }
        }).catch(error => {
            //console.log(error.response);
            let response = error.response.data;
            if (response.messageKey == "NEW_PASSWORD_REQUIRED") {
                setLoginButton('Change Password');
                setLoginUser({ ...LoginUser, newPasswordTextbox: true });
            } else if (response.messageKey == "USER_NOT_VERIFIED") {
                setLoginButton('Verify');
                setLoginUser({ ...LoginUser, codeTextbox: true });
            } else if (response.messageKey == "INVALID_PASSWORD_OR_USERNAME") {
                setLoginUserError("Incorrect email or password");
            } else {
                setLoginUserError(response.message);
            }
            setUser(null);
        });
    }

    const OnSubmit = (event) => {
        event.preventDefault();
        setLoginUserError("");
        setLoginButton(loadingSpinner);
        let user =
        {
            "clientId": Tenant.clientId,
            "clientSecret": ""
        };
        if (!Validators.isEmptyOrNullOrUndefined(LoginUser.username)) {

            user.username = LoginUser.username;
            if (!Validators.isEmptyOrNullOrUndefined(LoginUser.password) && LoginUser.password.length > 5 &&
                (LoginUser.newPassword == "" || (LoginUser.newPassword && LoginUser.newPassword.length > 5))) {

                if (LoginUser.newPasswordTextbox == true) {
                    if(LoginUser.passwordStrength == false) {
                        setLoginUserError("Please check your new password strength");
                    } else {
                        //console.log(LoginUser.newPassword , LoginUser.confirmNewPassword );
                        if (LoginUser.newPassword != "" && LoginUser.confirmNewPassword != "") {
                            if (LoginUser.newPassword == LoginUser.confirmNewPassword) {
                                user.password = LoginUser.password;
                                user.newPassword = LoginUser.newPassword;
                                login(user);
                            } else {
                                setLoginUserError("New password and confirm password must be match");
                                setLoginButton('Change Password');
                            }
                        } else {
                            setLoginUserError("New password and confirm password are mandatory");
                            setLoginButton('Change Password');
                        }
                    }
                } else if (LoginUser.codeTextbox == true) {
                    user.password = LoginUser.password;
                    const usercode =
                    {
                        "user": { email: LoginUser.username, code: LoginUser.code },
                        "clientId": Tenant.clientId,
                        "clientSecret": ""
                    };
                    LoginService.verifyCode(usercode).then(verify => {
                        //console.log(verify);
                        setLoginButton('Verify');
                        login(user);
                    }).catch(err => {
                        //console.log(err);
                        setLoginUserError("Verification code is Incorrect");
                        setLoginButton('Verify');
                    });
                } else {
                    user.password = LoginUser.password;
                    login(user);
                }
            } else {
                setLoginUserError("Password length is short");
            }
        } else {
            setLoginUserError("Email is empty");
        }
    }
    const setLoginUserError = (message) => {
        setLoginButton('Sign In');
        setLoginUser({ ...LoginUser, errorMessage: message });
    }

    const showPassword = () => {
        setLoginUser({ ...LoginUser, passwordShow: !LoginUser.passwordShow, errorMessage: "" });
    }

    const showNewPassword = () => {
        setLoginUser({ ...LoginUser, newPasswordShow: !LoginUser.newPasswordShow, errorMessage: "" });
    }
    const showNewComfirmPassword = () => {
        setLoginUser({ ...LoginUser, showNewComfirmPassword: !LoginUser.showNewComfirmPassword, errorMessage: "" });
    }


    const OnChangePassword = event => {
        setLoginUser({ ...LoginUser, password: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeNewPassword = value => {
        const password =  value.value.trim();
        setLoginUser({ ...LoginUser, newPassword: password, passwordStrength: (value.errors.length == 0 ? true: false), errorMessage: "" });
    }
    const OnChangeconfirmNewPassword = event => {
        setLoginUser({ ...LoginUser, confirmNewPassword: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeUsername = event => {
        setLoginUser({ ...LoginUser, username: event.target.value.trim(), errorMessage: "" });
    }

    const OnChangeCode = event => {
        setLoginUser({ ...LoginUser, code: event.target.value.trim(), errorMessage: "" });
    }

    const  HasPermission = (id, permissions) => {
        if(User.permissions && User.permissions.length > 0) {
            let hasValiPermissionLeve11 = permissions.find(p => p == "PS");
            let hasValiPermissionLevel2 = permissions.find(p => p == "PT");
            let hasValiPermissionLevel3 = permissions.find(p => p == id );
            if (hasValiPermissionLeve11 || hasValiPermissionLevel2 || hasValiPermissionLevel3) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div>
            {!loading ? (
                <div className="full-screen-height container">

                    <div className="container col-md-6 login-container " >
                        <form className="form-signin" onSubmit={OnSubmit} autoComplete="true">
                            <img src={Tenant.logo} alt="" height="90px" />
                            {/* <img src={logo} alt="" width="225px;"/> */}
                            {/* <h6 className="mt-4 mb-4 font-weight-bold sign-text">Sign in to continue with {Tenant.tenantName}</h6> */}
                            <br />
                            <br />
                            <br />
                            {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div>
                                    <label className="sr-only">Email address</label>
                                    <input type="email" value={LoginUser.username} onChange={OnChangeUsername} className="form-control mb-3" autoComplete="on" placeholder="Email address" required />

                                    <label className="sr-only">Password</label>
                                    <div className="input-group mb-3">
                                        <input type={LoginUser.passwordShow == true ? 'text' : 'password'} value={LoginUser.password} onChange={OnChangePassword} className="form-control" autoComplete="on" placeholder="Password" required />
                                        <i onClick={showPassword} className={LoginUser.passwordShow ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword"></i>
                                    </div>
                                </div>
                                : ""
                            }
                            {LoginUser.newPasswordTextbox ?
                                <div>
                                    <label className="sr-only">New Password</label>
                                    <PasswordField className="form-control" fieldId="togglePassword" type={LoginUser.newPasswordShow ? 'text' : 'password'} label="New Password" placeholder="New password" onStateChanged={OnChangeNewPassword} thresholdLength={5} minStrength={1} required />
                                    <i onClick={showNewPassword} className={LoginUser.newPasswordShow ? 'fa fa-eye-slash showNewPassoword' : 'fa fa-eye showNewPassoword'} id="togglePassword"></i>
                                </div>
                                : ""
                            }
                            {LoginUser.newPasswordTextbox ?
                                <div>
                                    <label className="sr-only">Confirm Password</label>
                                    <div className="input-group mb-3">
                                        <input type={LoginUser.showNewComfirmPassword == true ? 'text' : 'password'} value={LoginUser.confirmNewPassword} onChange={OnChangeconfirmNewPassword} className="form-control" autoComplete="off" placeholder="Confirm password" required />
                                        <i onClick={showNewComfirmPassword} className={LoginUser.showNewComfirmPassword ? 'fa fa-eye-slash showHidePassword' : 'fa fa-eye showHidePassword'} id="togglePassword"></i>
                                    </div>
                                </div>
                                : ""
                            }
                            {LoginUser.codeTextbox ?
                                <div className="pb-3">
                                    <span className="d-block form-hint">Account confirmation is required. please check your email for the verification code</span>
                                    <label className="sr-only">Verification Code</label>
                                    <input type="number" value={LoginUser.code} onChange={OnChangeCode} className="form-control" placeholder="Enter verification code" autoComplete="off" required />
                                    {LoginUser.resendTimeout == 0 ? 
                                          
                                          <a className="resend-login" style={{ textDecoration: 'none' }} href={void(0)} onClick={resendCode}>
                                                    Send Code
                                         </a>
                                            :
                                        <span>
                                            <p className="forget-p resend-login">{LoginUser.resendTimeout}</p>
                                        </span>  
                                    }
                                  
                                </div> : ""
                            }
                            {!Validators.isEmptyOrNullOrUndefined(LoginUser.errorMessage) ?

                                <div className="alert alert-danger" role="alert login-error-message">
                                    {LoginUser.errorMessage}
                                </div>

                                :
                                ""
                            }
                            {!LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div className="row mb-4 mt-3">
                                    <div className="col-lg-6">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                defaultChecked={LoginUser.keepSignIn}
                                                onChange={keepSignIn} value="remember-me" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label pt-1 pl-2" htmlFor="customCheck1"> Keep me signed in</label>
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="font-weight-bold forget-text">
                                            <a href=""></a>
                                            <a href="#" onClick={goToForgetPassword}>
                                                <p className="forget-p">Forgot password ?</p>
                                            </a>
                                        </label>
                                    </div>
                                </div>
                                : ""
                            }

                            <button className="btn btn-lg  btn-block login-btn btn-radius custom-btn-color-1  " type="submit">
                                {loginButton}
                            </button>

                            {(Tenant.basePath != '99ticks') && !LoginUser.newPasswordTextbox && !LoginUser.codeTextbox ?
                                <div>
                                    <div className="row mt-3 mb-2">
                                        <div className="col-lg-12 or-text">
                                            <h2><span>OR</span></h2>
                                        </div>
                                    </div>
                                    <button  onClick={goTOsignUpStudent} className="btn btn-lg  btn-block btn-radius custom-btn-color-2 fs-18" type="submit">
                                        Create New Account
                                    </button>
                                </div>

                                :

                                ""
                            }
                        </form>
                    </div>
                </div>) : <LoadingPage />}
        </div>
    );
}

export default Login;
