import { createContext, useState } from "react";

const  UserContext = createContext();

export const UserProvider = (props) => {

    const [User,setUser]  = useState(null);
    
    return (
        <UserContext.Provider value={[User,setUser]}>
            {props.children}
        </UserContext.Provider>
    );

}

export default UserContext;