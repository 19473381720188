import React, { useContext } from 'react';
import TenantContext from '../../protected-components/contexts/TenantCotext';

function Development(){

    const [Tenant, setTenant] = useContext(TenantContext);

    return (
      <div>
        <div className=" home-img d-flex">
          <div className="navigation">
              <h3>{Tenant.tenantName} Central Portal</h3>
              <img className="under-image" src="http://www.shahnet.com/images/page_under_construction.jpg" alt="User picture" />
          </div>
          <div className="row flex-grow">
            <div className="col-lg-7 mx-auto text-white">
              <div className="row align-items-center d-flex flex-row">
                <div className="col-lg-6 text-lg-right pr-lg-4">
                  <h1 className="display-1 mb-0"></h1>
                </div>
                {/* <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>  </h2>
                  <h3 className="font-weight-light">Central portal dashboard</h3>
                </div> */}
              </div>
              <div className="row mt-5">
                <div className="col-12 text-center mt-xl-2">
                  {/* <Link className="text-white font-weight-medium" to="/dashboard">Back to home</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
}

export default Development;
