import { createContext, useState } from "react";

const  TenantContext = createContext();

export const TenantProvider = (props) => {

    const [tenant, setTenant] = useState({
        basePath: null,
        colors: null,
        logo: null,
        showPages: false,
        status: false
    });

    return (
        <TenantContext.Provider value={[tenant, setTenant]}>
            {props.children}
        </TenantContext.Provider>
    );
}

export default TenantContext;