import apiService from "../../../shared/services/api-services/api-service";

class LoginService {

    Url = process.env.REACT_APP_BACKEND_AUTH_URL;
  
    async signIn(user) {
        return apiService.post(`${this.Url}auth/users/authenticate`, user);
    }

    async verifyUser() {
        return apiService.get(`${this.Url}auth/users/verify`);
    }

    async verifyCode(user) {
        return apiService.post(`${this.Url}umm/users/confirm`,user);
    }
}

export default new LoginService();