import HashLoader from "react-spinners/HashLoader";

function LoadingPage({ status }) {

    const override = "color: black !important;background: #fff;border: 20px solid #fff; border-radius: 50%;";

    
    return (
            <div className="middle-div">
                <HashLoader
                    css={override}
                    size={100}
                    color={"#247CFF"}
                    loading={status}
                />
            </div>
        );
}

export default LoadingPage;
