 class Validators {

     isEmptyOrNullOrUndefined(val) {
        if (val === null || val === '' || val === undefined || val === {}) {
            return true;
        }
        return false;
    }

     isNullOrUndefined(val) {
        if (val === null || val === undefined) {
            return true;
        }
        return false;
    }
     isArray(val) {
        return (!!val) && (val.constructor === Array);
    }
     isObject(val) {
        return (!!val) && (val.constructor === Object);
    }
     isNumber(val) {
        return !isNaN(val);
    }
}

export default new Validators();