
import { useContext, useState } from 'react';
import TenantContext from '../contexts/TenantCotext';
import UserContext from '../contexts/UserContext';

function SideBar() {

    const [toggle, setToggle] = useState(false);
    const [User, setUser] = useContext(UserContext);
    const [Tenant, setTenant] = useContext(TenantContext);

    const adminPortal = process.env.REACT_APP_ADMIN_URL + Tenant.basePath;
    const classPortal = process.env.REACT_APP_TUTER_URL + Tenant.basePath + "/home"
    const studentPortal = process.env.REACT_APP_STUDENT_URL + Tenant.basePath + "/home";
  
    const toggleSideBar = () =>{
        setToggle(!toggle);
    }
    
    const  HasPermission = (id) => {
        if(User.permissions && User.permissions.length > 0) {
            let hasValiPermissionLeve11 = User.permissions.find(p => p == "PS");
            let hasValiPermissionLevel2 = User.permissions.find(p => p == "PT");
            let hasValiPermissionLevel3 = User.permissions.find(p => p == id );
            // console.log(hasValiPermissionLeve11,hasValiPermissionLevel2,hasValiPermissionLevel3);
            if (hasValiPermissionLeve11 || hasValiPermissionLevel2 || hasValiPermissionLevel3) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div className={
            toggle ? 'sidebar page-wrapper chiller-theme not-toggled' : 'sidebar page-wrapper chiller-theme toggled'
          }>
            <nav id="sidebar" className="sidebar-wrapper">
                <div className="sidebar-content bg-white shadow">
                    <div id="toggle-sidebar">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="sidebar-header border-none">
                        {toggle ?  <i onClick={toggleSideBar} className="fa fa-angle-double-right"></i> :
                                   <i onClick={toggleSideBar} className="fa fa-angle-double-left"></i>
                        }                   
                        <div className="user-pic">
                            <img className="img-responsive img-rounded" src="https://www.seekpng.com/png/full/138-1387775_login-to-do-whatever-you-want-login-icon.png" alt="User picture" />
                        </div>
                        <div className="user-info">
                            <span className="user-name">
                                <strong>{User !== null ? User.fullName : ''}</strong>
                            </span>
                            <span className="user-role font-weight-bold">{User !== null ? User.userRole : ''}</span>
                            <span className="user-status">
                                <i className="fa fa-circle"></i>
                                {/* <button onClick={changeName}>change</button> */}
                            </span>
                        </div>
                    </div>
                    <div className="sidebar-menu border-top">
                        <ul className="text-left">
                            <li className="header-menu">
                                <span>General</span>
                            </li>   
                            {HasPermission("P2") ?
                                <li className="sidebar-dropdown pl-3">
                                    <a href={adminPortal}>
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        {!toggle ? <span>Admin Portal</span> : ""}
                                        {/*<span className="badge badge-pill badge-danger">New</span>*/}
                                    </a>
                                </li>

                                :
                                ""
                            }
                            {HasPermission("P7") ? 
                            <li className="sidebar-dropdown pl-3">
                                <a href={classPortal}>
                                    <i className="fa fa-book" aria-hidden="true"></i>
                                    {!toggle ? <span>Class Management</span> : ""}
                                    {/*<span className="badge badge-pill badge-danger">New</span>*/}
                                </a>
                            </li>
                             :
                             ""
                            }
                        </ul>
                    </div>
                </div>
                <div className="sidebar-footer">
                    {/* <a href="#">
                    <i className="fa fa-bell"></i>
                    <span className="badge badge-pill badge-warning notification">3</span>
                </a>
                <a href="#">
                    <i className="fa fa-envelope"></i>
                    <span className="badge badge-pill badge-success notification">7</span>
                </a>
                <a href="#">
                    <i className="fa fa-cog"></i>
                    <span className="badge-sonar"></span>
                </a>
                <a href="#">
                    <i className="fa fa-power-off"></i>
                </a> */}
                </div>
            </nav>
        </div>
    );
}

export default SideBar;
