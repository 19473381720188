import { Route, Switch, useLocation } from 'react-router-dom';
import Home from './protected-components/Home';
import ProtectedRoute from './protected-components/protected-route/ProtectedRoute';
import Error404 from './public-components/error-pages/Error404';
import ForgetPassword from './public-components/forget-password/ForgetPassword';
import Login from './public-components/login/Login';

function Main(props) {

    const location = useLocation();
    //console.log("location", location.pathname);
    const basePath = props.basePath;
    //console.log("basePath", basePath);

    return (
        <>
                <Switch>
                    <ProtectedRoute path={`/${basePath}`} basePath={basePath} component={Home} exact />
                    <Route path={`/${basePath}/login`} render={(props) => <Login basePath={basePath} />} exact />
                    <Route path={`/${basePath}/forget-password`} render={(props) => <ForgetPassword basePath={basePath} />} exact />
                    <Route path="*" component={Error404} />
                </Switch>
        </>
    );
}

export default Main;
