import React, { useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import jwtService from '../../shared/services/jwt-services/jwt-service';

function Error404() {

  const [ticks, setTicks] = useState({
    tenants: []
  });

 
  useEffect(() => {
      const sug = jwtService.getHistory();
      //console.log(sug);
      setTicks({tenants: sug });
  },[]);

  return (
    <div>
      <h1>{process.env.BACKEND_URL}</h1>
      <div className="d-flex align-items-center text-center error-page pt-5 pb-4 h-100">
        <div className="row flex-grow">
          <div className="col-lg-8 mx-auto color-black">
            <div className="row align-items-center d-flex flex-robackgroundw">
              <div className="col-lg-6 text-lg-right pr-lg-4">
                <h1 className="display-1 mb-0">404</h1>
              </div>
              <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                <h2>SORRY!</h2>
                <h3 className="font-weight-light">The page you’re looking for was not found.</h3><br />
                {/* <h3 className="font-weight-light">Please check the url and try again.</h3><br /> */}
              </div>
            </div>
            { ticks.tenants && ticks.tenants.length > 0 ? 
                (<div className="row mt-5 color-black" >
                <h4 >Suggestions</h4>
                <div className="row mt-5">
                  <div className="col-12 text-center mt-xl-2">
                  { ticks.tenants.map(function(tenant, i){
                    return (
                      <div className="card" key={i}>
                      <img className="card-img-top" src={tenant.logo} alt="Card image cap" />
                      <div className="card-body">
                        <Link className="btn btn-to btn-primary" to={"/"+tenant.url} onClick={() =>{setTimeout(()=>{ window.location.reload() }, 1000); }}>{tenant.name}</Link>
                      </div>
                    </div>
                    )
                   })
                  }
                  </div>
                </div>
              </div>) : ""
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404;
