
import jwtService from '../jwt-services/jwt-service';
import axios from 'axios';
import refreshTokenService from './refresh-token-service';

class ApiService {

  constructor() {
    axios.interceptors.response.use((response) => {
      //console.log('success intercepter');
      return response;
    }, function (error) {
      const originalRequest = error.config;
      //console.log('failed intercepter ' + error.response.status);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        refreshTokenService.refreshToken().then(refreshStatus => {
          if (refreshStatus) {
            var token = jwtService.getAccessToken();
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axios(originalRequest);
          } else {
            throw error;
          }
        });
      }else{
        //console.log(error.response);
        throw error;
      }
    });
  }

  setHeaders() {
    const headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
    var token = jwtService.getAccessToken();
    //console.log(token);
    if (token) {
      headersConfig.headers['Authorization'] = `Bearer ${token}`;
    }
    return headersConfig;
  }

  // Perform a GET Request
  async get(path) {
    return axios.get(`${path}`, this.setHeaders());
  }

  // Perform a post Request
  async post(path, data) {
    return axios.post(`${path}`, data, this.setHeaders());
  }


  // Perform a put Request
  async put(path, data) {
    return axios.put(`${path}`, data, this.setHeaders())
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  // Perform a put Request
  async delete(path, data) {
    return axios.delete(`${path}`, data, this.setHeaders())
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
}
export default new ApiService();
