import apiService from '../api-services/api-service';

class ConfigurationService {

    Url = process.env.REACT_APP_BACKEND_AUTH_URL;

    async getTenantConfig(tenantName) {
            return apiService.get(`${this.Url}umm/tenants/${tenantName}/configurations`);
    }
}
export default new ConfigurationService();