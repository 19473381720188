import apiService from '../api-services/api-service';

class UserService {
    Url = process.env.REACT_APP_BACKEND_AUTH_URL;

    async getUser() {
        return apiService.get(`${this.Url}auth/users/verify`).then(data => {
            const user = {
                emailVerified : data.data.data.emailVerified,
                userId : data.data.data['custom:userId'],
                tenantId : data.data.data['custom:tenantId'],
                fullName : data.data.data['name'],
                phoneNumber : data.data.data['phoneNumber'],
                permissions : data.data.data['custom:permissions'] === undefined ? [] :  data.data.data['custom:permissions'],
                userRole: data.data.data['custom:role'],
                email: data.data.data['email'],
                subscribeTenants : data.data.data['custom:subscribeTenants'] === undefined ? [] :  data.data.data['custom:subscribeTenants']
            }
            user.subscribeTenants = JSON.parse(user.subscribeTenants);
            return user;
        });
    }
}
export default new UserService();