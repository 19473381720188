import apiService from "../../../shared/services/api-services/api-service";

class ForgetPasswordService {

    Url = process.env.REACT_APP_BACKEND_AUTH_URL;
    constructor() {
       
    }

    async forgetPassword(user) {
        return apiService.post(`${this.Url}umm/users/forget-password`, user);
    }

    async resendCode(user) {
        return apiService.post(`${this.Url}umm/users/resend-code`, user);
    }

    async verifyCode(user) {
        return apiService.post(`${this.Url}umm/users/new-password`,user);
    }

}

export default new ForgetPasswordService();