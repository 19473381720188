import React,{ Fragment } from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwtService from '../../shared/services/jwt-services/jwt-service';


function ProtectedRoute({component: Component, basePath,...rest}) {

    return (
        <Route  {...rest}   
            render = { props => {
                if(jwtService.getAccessToken()) {
                    return <Component basePath={basePath} />;
                }else{
                    return <Redirect to={`/${basePath}/login`} {...props} prevLocation={props.location} />
                }
            }}
        />
    );
}

export default ProtectedRoute;