import JwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

class JWTService {
    
    basePath = null;
    constructor() {
        this.domain = process.env.REACT_APP_DOMAIN;
        this.basePathName = 'bPath';
        this.redirectTo = "redirectTo-"+process.env.REACT_APP_ENV;
    }


    getAccessTokenName(){
        return "accessToken-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }
    
    getRefreshTokenName(){
        return "refreshToken-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }
    
    getRedirectToName(){
        return "redirectTo-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }

    getClientIdName() {
        return "id-"+this.getBasePath()+"-"+ process.env.REACT_APP_ENV;
    }

    setBasePath(basePath){
        this.basePath = basePath;
        Cookies.set(this.basePathName, basePath, { domain: this.domain, path: '/' });
    }

    getBasePath(){
        if(this.basePath){
            return this.basePath;
        }else{
            return  Cookies.get(this.basePathName);
        }
    }
    
    setAccessToken(token,clientId) {
        try {
            const accessTokenName = this.getAccessTokenName();
            const clientIdName = this.getClientIdName();
            //console.log("token name :"+ accessTokenName);
            Cookies.set(accessTokenName, token, { domain: this.domain });
            Cookies.set(clientIdName, clientId, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('setAccessToken -> err', err);
            return false;
        }
    }

    setRefreshToken(token) {
        try {
            const refreshTokenName = this.getRefreshTokenName();
            //console.log("token name :"+ refreshTokenName);
            Cookies.set(refreshTokenName, token, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('setAccessToken -> err', err);
            return false;
        }
    }


    isAccessTokenExpired() {
        try {
            const accessTokenName = this.getAccessTokenName();
            //console.log("token name :"+ accessTokenName);
            var token = Cookies.get(accessTokenName)
            if (token) {
                var decodedToken = JwtDecode(token);
                //console.log(decodedToken);
                if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return true;
        }
    }
    getRedirectUrl() {
        try {
            var token = Cookies.get(this.redirectTo);
            if (token) {
                return token
            } else {
                return null;
            }
        } catch (ex) {
            //console.log(ex);
        }
    }
    removeRedirectUrl() {
        try {
            Cookies.remove(this.redirectTo, { domain: this.domain });
        } catch (ex) {
            //console.log(ex);
        }
    }

    getAccessToken() {
        try {
            const accessTokenName = this.getAccessTokenName();
            //console.log("token name :"+ accessTokenName);
            var token = Cookies.get(accessTokenName)
            //console.log(token)
            if (token) {
                const decodedToken = JwtDecode(token);
                //console.log(decodedToken);
                if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
                    return null;
                } else {
                    return token;
                }
            }else{
                return null;
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    getRefreshToken() {
        try {
            const refreshTokenName = this.getRefreshTokenName();
            //console.log("token name :"+ refreshTokenName);
            var token = Cookies.get(refreshTokenName)
            if (token) {
                return token;
            } else {
                return null;
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    getClientId() {
        try {
            const clientIdName = this.getClientIdName();
            //console.log("client name :"+ clientIdName);
            var token = Cookies.get(clientIdName);
            if (token) {
                return token;
            } else {
                return null;
            }
        } catch (err) {
            //console.log('getClientId -> err', err);
            return null;
        }
    }

    removeAccessToken() {
        try {
            const accessTokenName = this.getAccessTokenName();
            const clientIdName = this.getClientIdName();
            //console.log("token name :"+ accessTokenName);
            Cookies.remove(accessTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeAccessToken -> err', err);
            return false;
        }
    }

    removeRefreshToken() {
        try {
            const refreshTokenName = this.getRefreshTokenName();
            const clientIdName = this.getClientIdName();
            //console.log("token name :"+ refreshTokenName);
            Cookies.remove(refreshTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeRefreshToken -> err', err);
            return false;
        }
    }

    removeTokens() {
        try {
            const accessTokenName = this.getAccessTokenName();
            const refreshTokenName = this.getRefreshTokenName();
            const clientIdName = this.getClientIdName();
            Cookies.remove(refreshTokenName, { domain: this.domain });
            Cookies.remove(accessTokenName, { domain: this.domain });
            Cookies.remove(clientIdName, { domain: this.domain });
            return true;
        } catch (err) {
            //console.log('removeTokens -> err', err);
            return false;
        }
    }

    getHistory() {
        try {
            var sug = localStorage.getItem('99tick-history');
            //console.log(sug);
            try {
                const array = JSON.parse(sug);
                return array;
            } catch (ex) {
                //console.log(ex);
            }
        } catch (err) {
            //console.log('getAccessToken -> err', err);
            return null;
        }
    }

    setHistory(data) {
        try {
            const sug = Cookies.get('99tick-history');
            //console.log(sug);
            const tArray = [{ name: data.basePath, url: data.basePath, logo: data.logo }];
            
            localStorage.setItem('99tick-history', JSON.stringify(tArray));
        } catch (ex) {
            //console.log(ex);
        }
    }
}

export default new JWTService();